import { FunctionComponent } from 'react';
import AyzerWhite from '../../public/icons/ayzer-w.svg';

interface SplashScreenProps {
  hide: boolean;
}

const SplashScreen: FunctionComponent<SplashScreenProps> = ({ hide }) => {
  return (
    <div className={`ayzer-loading ${hide ? 'hide' : ''}`}>
      <AyzerWhite width='30%' height='100%' />
      <style jsx>{`
        .ayzer-loading {
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100%;
          background-color: var(--primary-color);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 1;
        }

        .hide {
          transition: visibility 0.3s linear, opacity 0.3s ease-in-out;
          opacity: 0;
          visibility: hidden;
        }
      `}</style>
    </div>
  );
};

export default SplashScreen;
