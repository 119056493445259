import { gql, TypedDocumentNode, useMutation } from '@apollo/client';
import RawSpinner from 'components/RawSpinner';
import { useNotification } from 'context/NotificationContext';
import { AuthPayload } from 'generated/schema-types';
import { NextPage } from 'next';
import Head from 'next/head';
import { setCookie } from 'nookies';
import React, { useEffect, useState } from 'react';
import AyzerLogo from '../../public/icons/ayzer-cl.svg';
import SuperMuelita from '../../public/icons/supermuelita.svg';
import Form from '../components/Form';
import SplashScreen from '../components/SplashScreen';
import { PageProps } from './_app';

export const LOGIN_MUTATION: TypedDocumentNode<{ login: AuthPayload }, { username: string; password: string }> = gql`
  mutation LoginMutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      employee {
        username
      }
    }
  }
`;
interface LoginData {
  login: AuthPayload;
}
const Login: NextPage<PageProps> = () => {
  const { newFeedbackNotification } = useNotification();

  const confirm = (data: LoginData): void => {
    const { login } = data;
    if (!login.token) {
      return;
    }
    setCookie({}, 'token', login.token, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7 * 1000
    });
    window.location.reload();
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [hideSplash, setHideSplash] = useState(false);

  const [userLogin, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: data => confirm(data),
    onError: () => {
      newFeedbackNotification({
        type: 'feedback',
        level: 'error',

        message: 'Hubo un problema al iniciar sesión. Intenta más tarde'
      });
      setError(true);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setHideSplash(true);
    }, 1000);
  }, []);

  return (
    <>
      <Head>
        <title>Iniciar sesión</title>
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta httpEquiv='X-UA-Compatible' content='ie=edge' />

        <link rel='apple-touch-icon' sizes='57x57' href='/favicon/apple-icon-57x57.png' />
        <link rel='apple-touch-icon' sizes='60x60' href='/favicon/apple-icon-60x60.png' />
        <link rel='apple-touch-icon' sizes='72x72' href='/favicon/apple-icon-72x72.png' />
        <link rel='apple-touch-icon' sizes='76x76' href='/favicon/apple-icon-76x76.png' />
        <link rel='apple-touch-icon' sizes='114x114' href='/favicon/apple-icon-114x114.png' />
        <link rel='apple-touch-icon' sizes='120x120' href='/favicon/apple-icon-120x120.png' />
        <link rel='apple-touch-icon' sizes='144x144' href='/favicon/apple-icon-144x144.png' />
        <link rel='apple-touch-icon' sizes='152x152' href='/favicon/apple-icon-152x152.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-icon-180x180.png' />
        <link rel='icon' type='image/png' sizes='192x192' href='/favicon/android-icon-192x192.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='96x96' href='/favicon/favicon-96x96.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/manifest.json' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='msapplication-TileImage' content='/favicon/ms-icon-144x144.png' />
        <meta name='theme-color' content='#ffffff' />
      </Head>

      <div className='login-container px-4'>
        <SplashScreen hide={hideSplash} />
        <div className='left-pane'>
          <AyzerLogo width='350px' height='100%' />
        </div>
        <div className='center-pane'>
          <div className='line'></div>
        </div>
        <div className='right-pane'>
          <div className='logo'>
            <SuperMuelita width='200px' height='100%' />
          </div>
          <p className='slogan-text max-w-sm text-center text-gray-600'>
            Comienza a llevar la atención a tus pacientes a otro nivel.
          </p>
          <Form
            onSubmit={(): void => {
              setError(false);
              userLogin({ variables: { username, password } });
            }}
            width='100%'
          >
            <div className='form max-w-md mx-auto'>
              <div className='form-control g-input-margin'>
                <input
                  id='username'
                  name='username'
                  type='text'
                  value={username}
                  placeholder='Usuario'
                  onChange={(e): unknown => setUsername(e.target.value)}
                  autoComplete='off'
                  required
                />
                <div className='g-invalid-feedback'></div>
              </div>
              <div className='form-control g-input-margin'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='off'
                  value={password}
                  placeholder='Contraseña'
                  onChange={(e): unknown => setPassword(e.target.value)}
                  required
                />
                <div className='g-invalid-feedback'></div>
              </div>
              <div className='recover'>
                {/* <a className='recover-link' href='/recuperar-contrasena'>
                  ¿Olvidaste tu contraseña?
                </a> */}
              </div>
              <div className='submit'>
                <div className={'g-invalid-feedback opacity-0 ' + (error && 'opacity-100')}>
                  Verifica tu usuario y contraseña
                </div>
                <button className='g-blue-button py-2 w-2/4 sm:w-full' disabled={loading && !error} type='submit'>
                  {loading && !error ? <RawSpinner width='25px' height='25px' /> : 'Iniciar sesión'}
                </button>
              </div>
            </div>
          </Form>
        </div>
        <style jsx>{`
          .login-container {
            display: grid;
            grid-template-areas: 'left center right';
            grid-template-columns: 49% 2% 49%;
            width: 100%;
            height: 100vh;
          }

          .form-control {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .left-pane {
            padding: 50px;
            grid-area: left;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .center-pane {
            grid-area: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .right-pane {
            grid-area: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .line {
            width: 1px;
            height: 95vh;
            border-right: #cccccc 1px solid;
          }

          .logo {
            margin: 2.5rem 0;
          }

          input {
            width: 100%;
          }

          .recover {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .recover-link {
            /* margin-left: 200px; */
          }

          .submit {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem 0;
          }

          .slogan-text {
            padding: 1rem;
          }

          a {
            color: var(--orange);
          }

          .terms {
            margin-top: 3rem;
            color: var(--gray-900);
          }

          @media screen and (max-width: 768px) {
            .login-container {
              display: grid;
              grid-template-areas:
                'right'
                'left';
              grid-template-columns: 100%;
              width: 100%;
            }

            .line {
              width: 95%;
              height: 1px;
              border-bottom: #cccccc 1px solid;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default Login;
